/* src/styles/PROJECTCOLORS.scss */
:root {
    --primary-color: #724732;       /* Bleu */
    --secondary-color: #2c201a;     /* Vert */
    --tertiary-color: #c0917a;      /* Violet */
    --neutral-light-color: #efefef; /* Blanc cassé */
    --neutral-dark-color: black;  /* Gris foncé */
    --neutral-dark-color-gradrient-to-end: #111119;  /* Gris foncé */
    --content-height: 90vh;
    --content-height-responsive-d: auto;

}