// Conteneur principal pour centrer la lune
.moon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d0d0d; // Fond noir pour simuler le ciel
    position: fixed;
    top: 20px;
    left: 20px;
  }
  
  // Style de la lune
  .moon {
    width: 30px;
    height: 30px;
    background: radial-gradient(circle at 30% 30%, #ffffff, #d9d9d9 60%, #9a9a9a 100%);
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 60px 20px rgba(255, 255, 255, 0.5); // Effet de brillance autour de la lune
  }
  
  // Cratères (4 cratères différents pour plus de variété)
  .crater {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(circle, #aaaaaa, #888888 60%, #555555);
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  
  // Cratères spécifiques
  .crater-1 {
    width: 2px;
    height: 2px;
    top: 20px;
    left: 50px;
  }
  
  .crater-2 {
    width: 2px;
    height: 2px;
    top: 50px;
    left: 100px;
  }
  
  .crater-3 {
    width: 2px;
    height: 2px;
    top: 120px;
    right: 40px;
  }
  
  .crater-4 {
    width: 2px;
    height: 2px;
    top: 30px;
    right: 70px;
  }