.scroll-line-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; // Empêche l'interaction avec le canvas
  }
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }