
body {
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    // font-family: "League Spartan", sans-serif;
    //font-family: "Playwrite DE Grund", cursive;
}

.main-container {
    width: 90vw;
    box-sizing: border-box;
}

.max-width-screen {
    max-width: 100%;
}

.content-height {
    height: var(--content-height);
    width: 100%;
}

.alyfe-web-logo {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    margin: 10px;
}

.alyfe-web-text {
    font-size: 1rem;
    color: white;
    position: absolute;
    bottom: 10px;
}

.navbar-container {
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 10vw;
    transition: all 0.5s ease;
}

.navbar-container:hover {
    background: var(--primary-color);
    border-left: 3px solid white;
}

.navbar-container-contact-me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar-container-menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar-container-icons {
    color: white;
    font-size: 1.7rem;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.fade-in-late {
    opacity: 0;
    transition: opacity 0.7s ease-in-out 0.3s, transform 2s;
    transform: translateX(4rem);
}

.fade-in-late.visible {
    opacity: 1;
    transform: translateX(0rem);
}

.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 2s;
    transform: translateY(-4rem);
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0rem);
}

.circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px; /* Ajustez selon la taille désirée */
    height: 50px;
    transform: translate(-50%, -50%); /* Centrer l'élément */
    stroke: white; /* Couleur du cercle */
    stroke-width: 1.5; /* Épaisseur du cercle */
    fill: none; /* Pas de remplissage */
    stroke-dasharray: 400; 
    transition: stroke-dashoffset 0.6s ease; /* Animation de l'offset */
}

.navbar-container-icons:hover .circle {
    stroke-dashoffset: 0; /* Faire apparaître le cercle */
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.width100percent {
    width: 100%;
}

.height100percent {
    height: 100%;
}

.width100vw {
    width: 100vw;
}

.height100vh {
    height: 100vh;
}


.flex-column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-start {
    justify-content: flex-start;
  }

.text-style-1 {
    font-size: 1.2rem;
}

.flex-row-contents-items-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-row-contents-spacebetween-items-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-row-contents-spacearound-items-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.input-style-1-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.input-style-1-label {
    color: var(--tertiary-color);
}

.textarea-style-1 {
    outline: none;
    padding: 10px 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--tertiary-color);
    background: transparent;
    color: white;
    height: 130px;
}

.input-style-1 {
    outline: none;
    padding: 10px 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--tertiary-color);
    background: transparent;
    color: white;
}

.background-radial-theme {
    background: radial-gradient(100% 50% at center,#2c201a,transparent);
}

.hightlights-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.hightlights-container-section-type-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.hightlights-section-type-1-style-1 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.hightlights-section-type-1-style-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.hightlights-section-type-1-style-3 {
    width: 35%;
    color: white;
    background: black;
    border-radius: 30px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.hightlights-section-type-1-style-4 {
    width: 50%;
    background: var(--primary-color);
    border-radius: 30px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.hightlights-container-section-type-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hightlights-section-type-2-style-1 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.hightlights-section-type-2-style-2 {
    width: 45%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hightlights-container-section-type-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    width: 100%;
}

.hightlights-container-section-type-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.container-pricing-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    width: 100%;
}

.hightlights-container-title {
    color: lightgray;
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    margin: 40px 0px 0px 0px;   
}

.hightlights-container-text {
    color: gray;
    width: 80%;
    font-size: 1.5rem;
    font-weight: normal;
}

.hightlights-container-text-bold {
    color: #c0917a;
    font-weight: bold;
    transition: all 0.5s;
}

.hightlights-container-text-bold:hover {
    color: var(--primary-color);
}

.hightlights-container-bento-text {
    color: lightgray;
    padding: 20px;
    background: var(--secondary-color);
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: normal;
    text-align: center;
    margin: 5px;
    border: 1px solid #724732;
}

.error_msg {
    color: red;
    font-weight: 600;
    font-size: 0.8rem;
    margin: 5px 0px;
}

.sucess_msg {
    color: var(--tertiary-color);
    font-weight: 600;
    font-size: 0.8rem;
    margin: 5px 0px;
}


.link_style_1 {
    color: #28D79A;
    text-decoration: none;
    text-align: center;
}
  
.link_style_1:hover {
    color: #28D79A;
    cursor: pointer;
    font-weight: bold;
}


.link_style_2 {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin: 100px 0px;
    transition: all 1s;
    padding: 20px 20px;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
    background: var(--primary-color);
}

.link_style_2-text {
    color: white;
    font-size: 1.5rem;
    white-space: nowrap;
    padding-right: 20px;
}

.link_style_2:hover {
    background: #724732;
    cursor: pointer;
}

.link_style_2:hover .link_style_2-icon {
    transform: rotate(-20deg);
}

.link_style_2-icon {
    color: white;
    font-size: 2rem;
    transition: all 0.7s;
}

/*
AUTHENTICATION
*/


.authentication_div {
    width: 60%;
    margin-top: 20px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  
.authentication_label_input {
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
    width: 100%;
    font-size: 0.5rem;
}

.authentication_div_input {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: rgba(146, 146, 147, 0.5);
    border-radius: 5px;
    width: 100%;
    height: 20px;
}

.authentication_div_input > i {
    color: rgb(149, 149, 149, 0.5);
    line-height: 30px;
    font-size: 30px;
    margin: 0.5rem;
    width: auto;
    font-size: 0.5rem;
}
  
.authentication_div_input > input {
    width: 100%;
    border: none;
    outline: none;
    color: rgb(20, 19, 19, 0.6);
    font-size: 0.5rem;
}


.authentication_div_margin_top_style_1 {
    margin-top: 20px;
    width: 100%;
}
  
  
  
.button_authentication_managers {
    padding: 8px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 0.5rem;
    color: white;
    background-color: #28D79A;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
}
  

.button_authentication_managers:hover {
    padding: 6px 20px;
    transition: all 0.3s ease-out;
    border-radius: 5px;
    background-color: white;
    border-color: #28D79A;
    border-width: 1px;
    border-style: solid;
    color: #28D79A;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.5rem;
}

/*
END AUTHENTICATION
*/



/*
3D PHONE AND LAPTOP
*/

.phone-container {
    box-sizing: border-box;
    padding: 5px;
    border-radius: 30px;
    width: 290px;
    height: 600px;
    box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
    transform-style: preserve-3d;
}

.phone-container-cirle-location {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: orange;
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.phone-container-video-gourmands-container {
    margin-right: 10px;
    height: 10rem;
    width: 6rem;
}

.phone-container-to-test {
    margin-right: 10px;
    height: 130px;
    width: 130px;
}

.phone-text-hover-black {
    border-radius: 10px;
    padding: 7px;
    font-size: 0.5rem;
    background: lightgray;
    margin-right: 10px;
    color: black;
}

.phone-text-hover-black:hover {
    background: black;
    color: white;
}

.phone-container-autour-de-vous {
    margin-top: 10px;
}

.laptop-container {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 30px;
    width: 550px;
    height: 390px;
    box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
    background: black;
    transform-style: preserve-3d;
}

.laptop-container-keyboard {
    height: 21px;
    background: #d1d2d4;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@keyframes bounce-phone {
    0% {transform: translateY(0px);}
    50% {transform: translateY(30px);}
    100% {transform: translateY(0px);}
}

/*
END 3D PHONE AND LAPTOP
*/


/*
REVIEW CARD
*/

.review-card-container {
    box-sizing: border-box;
    width: 400px;
    height: 330px;
    min-width: 400px;
    border-radius: 20px;
    background: black;
    padding: 20px;
    margin: 10px;
}

/*
END REVIEW CARD
*/

/*
PRICING CARD
*/

.pricing-card-container {
    box-sizing: border-box;
    width: 19vw;
    height: 25vw;
    min-width: 19vw;
    border-radius: 20px;
    background: var(--secondary-color);
    padding: 20px;
    margin: 10px;
    border: 1px solid var(--primary-color);
}

.pricing-tab {
    padding: 10px 20px;
    background: var(--secondary-color);
    width: 60px;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
}
.pricing-tab-left {
    padding: 10px 20px;
    background: var(--secondary-color);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 60px;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
}
.pricing-tab-right {
    padding: 10px 20px;
    background: var(--secondary-color);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 60px;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
}

.pricing-tab,
.pricing-tab-left,
.pricing-tab-right {
    transition: all 0.7s;
}

.pricing-tab:hover,
.pricing-tab-left:hover,
.pricing-tab-right:hover {
    background: var(--primary-color);
    cursor: pointer;
}

.pricing-tab-selected {
    background: var(--primary-color);
}

/*
END PRICING CARD
*/

/*
PORTFOLIO CARD
*/

.portfolio-card-container {
    box-sizing: border-box;
    border-radius: 20px;
    margin: 10px;
    padding: 20px;
}

.portfolio-card-img {
    border-radius: 10px;
    height: 400px;
    object-fit: cover;
}

.portfolio-link {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-top: 30px;
    transition: all 1s;
    padding: 20px 20px;
    border-radius: 20px;
    border: 1px solid #724732;
    background: #2c201a;
}

.portfolio-link:hover {
    background: #724732;
    cursor: pointer;
}

.portfolio-link:hover .portfolio-link-icon {
    transform: rotate(-20deg);
}

.portfolio-link-icon {
    color: white;
    font-size: 2rem;
    transition: all 0.7s;
}

/*
END PORTFOLIO CARD
*/


/*
BUTTON STYLES
*/
.button_style_1 {
    border: none;
    background: transparent;
    color: white;
    padding: 9px;
    border-radius: 15px;
    font-size: 15px;
    width: 100%;
    text-align: center;
    transition: all 1s ease-out;
}
  
.button_style_1:hover {
    background: var(--neutral-dark-color-gradrient-to-end);
    cursor: pointer;
    color: white;
    border-radius: 15px;
    font-weight: bold;
    font-size: 15px;
}

.button_style_2 {
    border: none;
    background: black;
    color: white;
    padding: 9px 20px;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
    transition: all 1s ease;
}
  
.button_style_2:hover {
    background: var(--neutral-dark-color-gradrient-to-end);
    cursor: pointer;
    color: white;
    border-radius: 15px;
    font-size: 15px;
}

.button_style_2:hover i {
    font-weight: bold;
    transition: all 0.7s ease;
    transform: rotate(-20deg);
}
/*
END BUTTON STYLES
*/


/*
RAIN EFFECT
*/
.background-rain-container {
    background: linear-gradient(to bottom, black, black);
    width: 100vw;
}

.rain-container {
    background: linear-gradient(to bottom, rgb(22, 22, 22), rgb(22, 22, 22));
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container-raindrop {
    animation-name: opacityraindrop;
    animation-iteration-count: infinite;
}

.pluie {
    width: 1px;
    height: 100px;
    background: linear-gradient(to bottom, #111119, rgb(70, 68, 68));
    position: absolute;
    animation-name: drop;
    animation-iteration-count: infinite;
    bottom: 100%;
    animation-timing-function: linear;
}

@keyframes opacityraindrop {
    0% {opacity: 1};
    90% {opacity: 0};
    100% {opacity: 0};
}

@keyframes drop {
    0% {bottom: 100%};
    100% {bottom: 0%};
}
/*
END RAIN EFFECT
*/




/*
REPONSIVE DESIGN
*/

/* Pour les écrans moyens (tablettes) */
@media (max-width: 1024px) {
    .main-container {
        width: 100vw;
    }
    .navbar-container {
        background: var(--primary-color);
        height: 60px;
        position: fixed;
        bottom: 5px;
        width: 80vw;
        transition: all 0.5s ease;
        border-radius: 30px;
        left: 50%;
        top: auto;
        transform: translateX(-50%); /* Déplace l'élément de moitié de sa propre largeur pour un alignement centré */
    }
    .navbar-container:hover {
        background: var(--primary-color);
        border: none;
    }
    .navbar-container-contact-me {
        display: none;
    }
    .navbar-container-menu {
        flex-direction: row;
    }
    .navbar-container-icons {
        color: white;
        font-size: 1.4rem;
        padding: 10px;
        cursor: pointer;
        position: relative;
    }
    .alyfe-web-text {
        display: none;
    }

    /*1*/
    .hightlights-container-section-type-1 {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 150px;
    }
    .hightlights-section-type-1-style-1 {
        width: 99%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .hightlights-section-type-1-style-2 {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hightlights-section-type-1-style-3 {
        width: 99%;
        color: white;
        background: black;
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    
    .hightlights-section-type-1-style-4 {
        width: 99%;
        background: var(--primary-color);
        border-radius: 30px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    /*2*/
    .hightlights-container-section-type-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;
    }

    .hightlights-section-type-2-style-1 {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .hightlights-section-type-2-style-2 {
        width: 90%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    /*4*/
    .hightlights-container-section-type-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 150px;
    }
    /*3*/
    .hightlights-container-section-type-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        width: 100%;
        margin-bottom: 150px;
    }
    /*5*/
    .hightlights-container-section-type-5 {
        margin-bottom: 150px;
    }
    .container-pricing-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
        width: 100%;
    }
    .pricing-card-container {
        box-sizing: border-box;
        width: 99%;
        height: auto;
        border-radius: 20px;
        background: var(--secondary-color);
        padding: 20px;
        margin: 10px;
        border: 1px solid var(--primary-color);
    }
    .content-height {
        height: var(--content-height-responsive-d);
        width: 100%;
    }

    .hightlights-container-bento-text {
        color: lightgray;
        padding: 10px;
        background: var(--secondary-color);
        border-radius: 15px;
        font-size: 0.9rem;
        font-weight: normal;
        text-align: center;
        margin: 5px;
        border: 1px solid #724732;
    }

    .hightlights-container-title {
        color: lightgray;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        margin: 40px 0px 0px 0px;   
    }    

    .portfolio-card-img {
        border-radius: 10px;
        height: 200px;
        object-fit: cover;
    }

    .portfolio-link {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        display: flex;
        margin-top: 30px;
        transition: all 1s;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #724732;
        background: #2c201a;
    }

    /*
    3D PHONE AND LAPTOP
    */

    .phone-container {
        box-sizing: border-box;
        padding: 5px;
        border-radius: 30px;
        width: 200px;
        height: 400px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        transform-style: preserve-3d;
    }

    .phone-container-cirle-location {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: orange;
        font-size: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;
    }
    
    .phone-container-video-gourmands-container {
        margin-right: 10px;
        height: 120px;
        width: 70px;
    }

    .phone-container-to-test {
        margin-right: 10px;
        height: 80px;
        width: 80px;
    }
    
    .phone-container-autour-de-vous {
        display: none;
    }

    .laptop-container {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: 30px;
        width: 350px;
        height: 220px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        background: black;
        transform-style: preserve-3d;
    }

    .laptop-container-keyboard {
        height: 11px;
        background: #d1d2d4;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }


    .authentication_div_margin_top_style_1 {
        margin-top: 2px;
        width: 100%;
    }

    .button_authentication_managers {
        padding: 6px 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 0.5rem;
        color: white;
        background-color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
      
    
    .button_authentication_managers:hover {
        padding: 6px 10px;
        transition: all 0.3s ease-out;
        border-radius: 5px;
        background-color: white;
        border-color: #28D79A;
        border-width: 1px;
        border-style: solid;
        color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.5rem;
    }
    
    .authentication_div_input {
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: rgba(146, 146, 147, 0.5);
        border-radius: 5px;
        width: 100%;
        height: 20px;
    }
      
    .authentication_label_input {
        font-weight: 600;
        text-align: left;
        margin-top: 2px;
        width: 100%;
        font-size: 0.3rem;
    }

    /*
    END 3D PHONE AND LAPTOP
    */ 
}

/* Pour les petits écrans (smartphones) */
@media (max-width: 768px) {
    .main-container {
        width: 100vw;
    }
    .navbar-container {
        background: var(--primary-color);
        height: 60px;
        position: fixed;
        bottom: 5px;
        width: 80vw;
        transition: all 0.5s ease;
        border-radius: 30px;
        left: 50%;
        top: auto;
        transform: translateX(-50%); /* Déplace l'élément de moitié de sa propre largeur pour un alignement centré */
    }
    .navbar-container:hover {
        background: var(--primary-color);
        border: none;
    }
    .navbar-container-contact-me {
        display: none;
    }
    .navbar-container-menu {
        flex-direction: row;
    }
    .navbar-container-icons {
        color: white;
        font-size: 1.4rem;
        padding: 10px;
        cursor: pointer;
        position: relative;
    }
    .alyfe-web-text {
        display: none;
    }
    /*1*/
    .hightlights-container-section-type-1 {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 150px;
    }
    .hightlights-section-type-1-style-1 {
        width: 99%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .hightlights-section-type-1-style-2 {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hightlights-section-type-1-style-3 {
        width: 99%;
        color: white;
        background: black;
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    
    .hightlights-section-type-1-style-4 {
        width: 99%;
        background: var(--primary-color);
        border-radius: 30px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    /*2*/
    .hightlights-container-section-type-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;
    }

    .hightlights-section-type-2-style-1 {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .hightlights-section-type-2-style-2 {
        width: 90%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    /*4*/
    .hightlights-container-section-type-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 150px;
    }
    /*3*/
    .hightlights-container-section-type-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        width: 100%;
        margin-bottom: 150px;
    }
    /*5*/
    .hightlights-container-section-type-5 {
        margin-bottom: 150px;
    }
    .container-pricing-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
        width: 100%;
    }
    .pricing-card-container {
        box-sizing: border-box;
        width: 99%;
        height: auto;
        border-radius: 20px;
        background: var(--secondary-color);
        padding: 20px;
        margin: 10px;
        border: 1px solid var(--primary-color);
    }
    .content-height {
        height: var(--content-height-responsive-d);
        width: 100%;
    }

    .hightlights-container-bento-text {
        color: lightgray;
        padding: 10px;
        background: var(--secondary-color);
        border-radius: 15px;
        font-size: 0.9rem;
        font-weight: normal;
        text-align: center;
        margin: 5px;
        border: 1px solid #724732;
    }

    .hightlights-container-title {
        color: lightgray;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        margin: 40px 0px 0px 0px;   
    }    

    .portfolio-card-img {
        border-radius: 10px;
        height: 200px;
        object-fit: cover;
    }

    .portfolio-link {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        display: flex;
        margin-top: 30px;
        transition: all 1s;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #724732;
        background: #2c201a;
    }

    
    /*
    3D PHONE AND LAPTOP
    */

    .phone-container {
        box-sizing: border-box;
        padding: 5px;
        border-radius: 30px;
        width: 200px;
        height: 400px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        transform-style: preserve-3d;
    }

    .phone-container-cirle-location {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: orange;
        font-size: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;
    }
    
    .phone-container-video-gourmands-container {
        margin-right: 10px;
        height: 120px;
        width: 70px;
    }
    
    .phone-container-to-test {
        margin-right: 10px;
        height: 80px;
        width: 80px;
    }

    .phone-container-autour-de-vous {
        display: none;
    }

    .laptop-container {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: 30px;
        width: 350px;
        height: 220px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        background: black;
        transform-style: preserve-3d;
    }

    .laptop-container-keyboard {
        height: 11px;
        background: #d1d2d4;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .authentication_div_margin_top_style_1 {
        margin-top: 2px;
        width: 100%;
    }

    .button_authentication_managers {
        padding: 6px 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 0.5rem;
        color: white;
        background-color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
      
    
    .button_authentication_managers:hover {
        padding: 6px 10px;
        transition: all 0.3s ease-out;
        border-radius: 5px;
        background-color: white;
        border-color: #28D79A;
        border-width: 1px;
        border-style: solid;
        color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.5rem;
    }
    
    .authentication_div_input {
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: rgba(146, 146, 147, 0.5);
        border-radius: 5px;
        width: 100%;
        height: 20px;
    }
      
    .authentication_label_input {
        font-weight: 600;
        text-align: left;
        margin-top: 2px;
        width: 100%;
        font-size: 0.3rem;
    }

    /*
    END 3D PHONE AND LAPTOP
    */
}

/* Pour les très petits écrans (petits smartphones) */
@media (max-width: 480px) {
    .main-container {
        width: 100vw;
    }
    .navbar-container {
        background: var(--primary-color);
        height: 60px;
        position: fixed;
        bottom: 5px;
        width: 80vw;
        transition: all 0.5s ease;
        border-radius: 30px;
        left: 50%;
        top: auto;
        transform: translateX(-50%); /* Déplace l'élément de moitié de sa propre largeur pour un alignement centré */
    }
    .navbar-container:hover {
        background: var(--primary-color);
        border: none;
    }
    .navbar-container-contact-me {
        display: none;
    }
    .navbar-container-menu {
        flex-direction: row;
    }
    .navbar-container-icons {
        color: white;
        font-size: 1.4rem;
        padding: 10px;
        cursor: pointer;
        position: relative;
    }
    .alyfe-web-text {
        display: none;
    }

    /*1*/
    .hightlights-container-section-type-1 {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 150px;
    }
    .hightlights-section-type-1-style-1 {
        width: 99%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .hightlights-section-type-1-style-2 {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hightlights-section-type-1-style-3 {
        width: 99%;
        color: white;
        background: black;
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    
    .hightlights-section-type-1-style-4 {
        width: 99%;
        background: var(--primary-color);
        border-radius: 30px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    /*2*/
    .hightlights-container-section-type-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;
    }

    .hightlights-section-type-2-style-1 {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .hightlights-section-type-2-style-2 {
        width: 90%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    /*4*/
    .hightlights-container-section-type-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 150px;
    }
    /*3*/
    .hightlights-container-section-type-3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        width: 100%;
        margin-bottom: 150px;
    }
    /*5*/
    .hightlights-container-section-type-5 {
        margin-bottom: 150px;
    }
    .container-pricing-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;
        width: 100%;
    }
    .pricing-card-container {
        box-sizing: border-box;
        width: 99%;
        height: auto;
        border-radius: 20px;
        background: var(--secondary-color);
        padding: 20px;
        margin: 10px;
        border: 1px solid var(--primary-color);
    }
    .content-height {
        height: var(--content-height-responsive-d);
        width: 100%;
    }

    .hightlights-container-bento-text {
        color: lightgray;
        padding: 10px;
        background: var(--secondary-color);
        border-radius: 15px;
        font-size: 0.9rem;
        font-weight: normal;
        text-align: center;
        margin: 5px;
        border: 1px solid #724732;
    }

    .hightlights-container-title {
        color: lightgray;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        margin: 40px 0px 0px 0px;   
    }    

    .portfolio-card-img {
        border-radius: 10px;
        height: 200px;
        object-fit: cover;
    }

    .portfolio-link {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        display: flex;
        margin-top: 30px;
        transition: all 1s;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #724732;
        background: #2c201a;
    }

    .hightlights-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
    }

    /*
    3D PHONE AND LAPTOP
    */

    .phone-container {
        box-sizing: border-box;
        padding: 5px;
        border-radius: 30px;
        width: 200px;
        height: 400px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        transform-style: preserve-3d;
    }

    .phone-container-cirle-location {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: orange;
        font-size: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;
    }
    
    .phone-container-video-gourmands-container {
        margin-right: 10px;
        height: 120px;
        width: 70px;
    }

    .phone-container-to-test {
        margin-right: 10px;
        height: 80px;
        width: 80px;
    }
    
    .phone-container-autour-de-vous {
        display: none;
    }

    .laptop-container {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: 30px;
        width: 350px;
        height: 220px;
        box-shadow: 5px 5px 5px rgb(211, 211, 211, 0.2), 5px -5px 5px rgb(211, 211, 211, 0.2);
        background: black;
        transform-style: preserve-3d;
    }

    .laptop-container-keyboard {
        height: 11px;
        background: #d1d2d4;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }


    .authentication_div_margin_top_style_1 {
        margin-top: 2px;
        width: 100%;
    }

    .button_authentication_managers {
        padding: 6px 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 0.5rem;
        color: white;
        background-color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
      
    
    .button_authentication_managers:hover {
        padding: 6px 10px;
        transition: all 0.3s ease-out;
        border-radius: 5px;
        background-color: white;
        border-color: #28D79A;
        border-width: 1px;
        border-style: solid;
        color: #28D79A;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.5rem;
    }
    
    .authentication_div_input {
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: rgba(146, 146, 147, 0.5);
        border-radius: 5px;
        width: 100%;
        height: 20px;
    }
      
    .authentication_label_input {
        font-weight: 600;
        text-align: left;
        margin-top: 2px;
        width: 100%;
        font-size: 0.3rem;
    }

    /*
    END 3D PHONE AND LAPTOP
    */

}

/*
END RESPONSIVE DESIGN
*/